import React, { useEffect, useState } from "react";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import { Button, ConfirmationModal, Dropdown, Modal, Table, TextButton } from "UI";
import { Loader, apiGenerator } from "Utils";
import { EXECUTABLE_HELPER_EXECUTION_ORDERS, EXECUTABLE_HELPER_EXECUTION_PERIODS } from "Constants/streaming.constants";
import { useMutation, useQuery } from "@tanstack/react-query";

import "./ExecutableHelpersModal.styles.scss";

const ExecutableHelpersModal = ({ executable, setShowExecutableHelpersModal, translate }) => {
  const [selectedExecutableId, setSelectedExecutableId] = useState(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showNewHelperForm, setShowNewHelperForm] = useState(false);
  const [executionOrder, setExecutionOrder] = useState("before_app_start");
  const [executionPeriod, setExecutionPeriod] = useState("once");
  const [refetchCount, setRefetchCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const reloadDebounce = 200;

  const executableList = executable.attributes.executable_list;

  const [path, setPath] = useState(executableList[0]);

  const columns = [
    { name: "ID", weight: 0 },
    { name: "Order" },
    { name: "Executable" },
    { name: "Placement" },
    { name: "Frequency" },
    { name: "delete", hide: true },
  ];

  const {
    data: executableHelpersData,
    isInitialLoading,
    refetch,
  } = useQuery({
    queryKey: [APP_STREAMING_API_ENDPOINTS.VENDOR_EXECUTABLE_HELPERS, executable.id, refetchCount],
    queryFn: () => {
      return apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.VENDOR_EXECUTABLE_HELPERS, {
        params: { vendor_executable_id: executable.id },
      });
    },
  });

  const deleteExecutableMutation = useMutation({
    mutationFn: (id) => {
      return apiGenerator("delete")(APP_STREAMING_API_ENDPOINTS.VENDOR_EXECUTABLE_HELPER(id), {
        id,
      });
    },
    onSuccess: () => {
      refetch();
      setRefetchCount(refetchCount + 1);
    },
  });

  const data = executableHelpersData?.data?.executable_helpers?.map((helper) => {
    return {
      id: helper.id,
      order: executableHelpersData?.data?.executable_helpers?.indexOf(helper) + 1,
      executable: helper.attributes?.path?.split("/")?.pop(),
      placement: translate(
        `appStreamingModals.executableHelpers.form.executionOrder.options.${helper.attributes.execution_order}`,
      ),
      frequency: translate(
        `appStreamingModals.executableHelpers.form.executionPeriod.options.${helper.attributes.execution_period}`,
      ),
      delete: (
        <div className="action-buttons">
          <TextButton
            text="Delete"
            color="gray-3"
            onClick={() => {
              setSelectedExecutableId(helper.id);
              setShowDeleteConfirmationModal(true);
            }}
            className="hover-on-aqua-main"
          />
        </div>
      ),
    };
  });

  const createExecutableHelperMutation = useMutation({
    mutationFn: () => {
      return apiGenerator("post")(APP_STREAMING_API_ENDPOINTS.VENDOR_EXECUTABLE_HELPERS, {
        vendor_executable_id: executable.id,
        path,
        execution_order: executionOrder,
        execution_period: executionPeriod,
        execution_type: path.includes(".exe") ? "exe" : "power_shell",
      });
    },
    onSuccess: () => {
      refetch();
      setRefetchCount(refetchCount + 1);
      setShowNewHelperForm(false);
    },
  });

  const newHelperForm = () => {
    return (
      <>
        <div className="new-helper-form">
          <h3>{translate("appStreamingModals.executableHelpers.form.executable.label")}</h3>
          <Dropdown
            underlined
            className="app-folder-box"
            value={path}
            defaultSelected={path}
            handleSelectedOptionChange={(option) => setPath(option)}
            options={executableList}
          />
          <div className="flex-options">
            <div>
              <h3>{translate("appStreamingModals.executableHelpers.form.executionOrder.label")}</h3>
              <Dropdown
                className="app-folder-box"
                value={executionOrder}
                defaultSelected={executionOrder}
                options={EXECUTABLE_HELPER_EXECUTION_ORDERS}
                handleSelectedOptionChange={(option) => setExecutionOrder(option)}
                mapFunction={(option) => {
                  return translate(`appStreamingModals.executableHelpers.form.executionOrder.options.${option}`);
                }}
              />
            </div>
            <div>
              <h3>{translate("appStreamingModals.executableHelpers.form.executionPeriod.label")}</h3>
              <Dropdown
                className="app-folder-box"
                value={executionPeriod}
                defaultSelected={executionPeriod}
                options={EXECUTABLE_HELPER_EXECUTION_PERIODS}
                handleSelectedOptionChange={(option) => setExecutionPeriod(option)}
                mapFunction={(option) => {
                  return translate(`appStreamingModals.executableHelpers.form.executionPeriod.options.${option}`);
                }}
              />
            </div>
          </div>
        </div>
        <div className="buttons-container">
          <TextButton text="Cancel" color="gray-3" onClick={() => setShowNewHelperForm(false)} />
          <TextButton
            text="Add New Executable"
            color="aqua-main"
            onClick={() => {
              createExecutableHelperMutation.mutate();
            }}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    if (isInitialLoading || createExecutableHelperMutation.isLoading || deleteExecutableMutation.isLoading) {
      setLoading(true);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, reloadDebounce);
    }
  }, [isInitialLoading || createExecutableHelperMutation.isLoading || deleteExecutableMutation.isLoading]);

  const content = () => {
    if (loading) {
      return <Loader />;
    }

    if (showNewHelperForm) {
      return newHelperForm();
    }

    if (executableHelpersData?.data?.executable_helpers?.length === 0) {
      return (
        <div className="no-helpers-content">
          <p className="header">{translate("appStreamingModals.executableHelpers.noHelpers.header")}</p>
          <p className="description">{translate("appStreamingModals.executableHelpers.noHelpers.description")}</p>
          <Button
            text="Add New Executable / Script"
            iconLeft="plus"
            color="light-navy"
            onClick={() => {
              setShowNewHelperForm(true);
            }}
          />
        </div>
      );
    }

    return (
      <>
        <Table columns={columns} data={data} className="application-versions-table" />
        <Button
          text="Add New Executable / Script"
          iconLeft="plus"
          color="light-navy"
          onClick={() => {
            setShowNewHelperForm(true);
          }}
        />
      </>
    );
  };

  if (showDeleteConfirmationModal) {
    return (
      <ConfirmationModal
        className="confirm-version-change-modal"
        closeOnOverlayClick
        closeAction={() => {
          setShowDeleteConfirmationModal(false);
        }}
        headerText={translate("appStreamingModals.executableHelpers.deleteHelper.header")}
        descriptionText={translate("appStreamingModals.executableHelpers.deleteHelper.description")}
        confirmText="Confirm"
        confirmAction={() => {
          deleteExecutableMutation.mutate(selectedExecutableId);
          setShowDeleteConfirmationModal(false);
        }}
        secondaryText="Cancel"
        secondaryAction={() => {
          setShowDeleteConfirmationModal(false);
        }}
      />
    );
  }

  return (
    <Modal
      className="executable-helpers-modal"
      closeOnOverlayClick
      topRightIcon="close"
      topRightIconAction={() => setShowExecutableHelpersModal(false)}
      headerText={translate("appStreamingModals.executableHelpers.header")}
      descriptionText={translate("appStreamingModals.executableHelpers.description")}
      closeAction={() => setShowExecutableHelpersModal(false)}
      big
    >
      {content()}
    </Modal>
  );
};

export default ExecutableHelpersModal;
